import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import "./Testimonials.scss";
const Testimonials = (props) => {
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        pauseOnHover: false,
        arrows: false,
        mobileFirst: true,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <span>&lt;</span>,
        nextArrow: <span>&rgt;</span>,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false
                },
            },
        ],
    };

    return (
        <React.Fragment>
            <section >
                    {props.Module && 
                     <Container  className="testimonial-wrapper">
                        <div className="testimonial-content">
                            <h2 className="testimonial-heading">{props.Module.Heading}</h2>
                            <Slider {...settings} className="testimonial_slider">
                                {props.Module?.Review_content?.map((item, index) => {
                                    return (
                                        <div className="slide_item">
                                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                                <div className="review-content">
                                                    <p>{item.Review_content}</p>
                                                    <p>{item.Review_name}</p>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    
                </Container>}
            </section>
        </React.Fragment>
    )
}
export default Testimonials;