import React, {useState, useEffect, useRef, createRef} from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"

import Layout from "../components/layout";
import SEO from "../components/seo";
import {Office_Details} from "../queries/common_use_query"

import OffcDetails from "../components/PersonDetails/OfficeDetails/OfficeDetails"
import OfficeLinks from "../components/PersonDetails/OfficeLinks"
import OpeningHours from "../components/PersonDetails/OpeningHours/OpeningHours"
import Teams from "../components/PersonDetails/OurTeam/OurTeam"
import ContactFormBottom from "../components/FormBottom/ContactFormBottom";
import WhatWeDo from "../components/PersonDetails/WhatWeDo/WhatWeDo";
import ContactMap from "../components/ContactPage/ContactMap/ContactMap";
import Loadable from "@loadable/component"


import {capitalize} from '../components/common/utils';

import Meet_The_Team  from '../components/InnerPage/Meet_The_Team/Office_Team_Section'

import BrandLogo from "../images/logo002.svg";

import $ from "jquery"
import { useStaticQuery, graphql } from "gatsby"
import Testimonials from "../components/Testimonials/Testimonials";

const Team = Loadable(() => import ('../components/Home/OurTeam/Team'))

function OfficeDetailsTemplate(props) {

  //console.log("location", props.location.hash);
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [menu_heading, setMenuHeading] = useState(false);
  const [state, setState] = React.useState({
      showMenu: false,
  });

  const [office_details, SetDetails] = useState("");

  const {loading, error, data} = Office_Details(props.pageContext?.slug);

  useEffect(()=>{
    data && data.offices.length > 0 && SetDetails(data.offices[0]);
  },[data])

  const global_data = useStaticQuery(graphql`
    query OfficeContactForm {  
      glstrapi{
        globalModule {

          Office_Contact_Form {
            Office_Contact_Module {
              Dynamics_User_ID
              Email_ID
              office {
                id
                Name
              }
            }
          }
        }
      }
    }
  `)

  var global_module = global_data.glstrapi?.globalModule?.Office_Contact_Form?.Office_Contact_Module;

  var myMetaTitle = "";
  var myMetaDesc = "";
  var myMetaImg = "";
  var page_path = props.pageContext.slug;

  if( props?.pageContext?.title ) {
      myMetaTitle = "Property Consultants and Auctioneers East & North London";
      myMetaDesc = "Our property consultants and auctioneers in East & North London offer expert property advice for selling, buying, letting, and valuations. Contact us to see how we can help.";
  }

  if (props.pageContext.title == 'City and City Fringe') {
      myMetaTitle = "Property Consultants & Advisors | Commercial Agents London";
      myMetaDesc = "Our property consultants in City and City Fringe offer specialist property advice when selling, buying, letting, renting and valuing. Contact us to get assistance from expert commercial agents in London.";
  }

  let processedImages = JSON.stringify({});
  const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev";

  if (office_details?.imagetransforms?.Image_Transforms) {
    processedImages = office_details?.imagetransforms?.Image_Transforms;
    var resized_images = JSON.parse(processedImages);
    const SrcCftle = office_details?.Image && office_details?.Image?.url.substring(office_details?.Image && office_details?.Image?.url.indexOf(STAGE_ENV));
    const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['480x320'] : 
                         resized_images[SrcCftle]?.png ? resized_images[SrcCftle]?.png['480x320'] : office_details?.Image && office_details?.Image?.url;
    myMetaImg = ProcessedUrl;
  }

  useEffect(()=>{
    var prevState = props.location.state;
    //console.log("prevState", prevState);
    if(prevState && prevState.form_id){
      office_details && office_details.Name && scrollToPos(prevState.form_id, 500);
    }
  },[office_details])
  

  const scrollToPos = (div_id, timeout) =>{
    setTimeout(function(){
      $('html, body').animate({
        scrollTop: $("#"+div_id).offset().top - 100
      }, 'smooth');
    }, timeout ? timeout : 0)
  }

  // Header handle click
  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu });
    if (state.showMenu == true) {
        document.body.className = "";
    } else {
        document.body.classList.add("overflow-hidden");
    }
  };
  // Header handle click

  const openHam = () => {
      setOpen(!open);
  };

  const openSearch = (menu_title) => {
      setSearch(!search);
      handlerClick();
      setMenuHeading(menu_title)
  };
  const closeSearch = () => {
      setSearch(!search);
      handlerClick();
  };


  var office_det = global_module.filter(item => item.office.id === office_details.id);
  return (
    loading ? (
          <section className={"loader-wrapper"}>
              <div id="loader-wrapper">
                  <div id="loader" className="new-loader">
                      <img className="logo-white" src={BrandLogo} alt="logo"/>
                  </div>
              </div>
          </section>
      ):
      <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header person-header details-header office_details_templates"} closeSearch={closeSearch} handlerClick={handlerClick} menu_heading={menu_heading} search={search} open={open} popular_search_slug="contact-us">
        
        <SEO title={myMetaTitle} description={myMetaDesc} image={myMetaImg}/>
        
        <Helmet bodyAttributes={{ class: `body_cls` }} />

        <OfficeLinks page_title={office_details && office_details.Name}/>
        {
          office_details && 
          <div className="people_details_wrap offc_det">

            <OffcDetails office_details={office_details} handleClick={scrollToPos} location={props.location} />   
            
            {/* { office_details.Opening_Hours && office_details.Opening_Hours.length > 0 && 
            <OpeningHours Opening_Hours={office_details.Opening_Hours} />
            }  */}
            
            {/* { office_details.meet_our_teams && (office_details.meet_our_teams.length > 0 && office_details.meet_our_teams.length <= 2) && <Teams meet_our_teams={office_details.meet_our_teams} /> }  */}

            {/* { office_details.meet_our_teams && office_details.meet_our_teams.length > 2  && <Meet_The_Team meet_our_teams={office_details.meet_our_teams}/> } */}

            { office_details.What_We_Do && <WhatWeDo What_We_Do={office_details.What_We_Do}/> }

            <Team Module={office_details?.Modules?.length > 0 && office_details?.Modules.filter(Module => Module.__typename === "ComponentComponentOurPeopleComponent")[0]}/>

            {office_details.Latitude && office_details.Longitude && <ContactMap office_details={office_details} all_tabs={false} infoWindow={false} />}

            <Testimonials Module={office_details?.Modules?.length > 0 && office_details?.Modules.filter(Module => Module.__typename === "ComponentComponentReviewsModule")[0]}/>

            <ContactFormBottom Module={office_details?.Modules?.length > 0 && office_details?.Modules.filter(Module => Module.__typename === "ComponentComponentShowContactFormModule")} member_name={office_details.Name} form_name={(office_details.Name).toLowerCase()} to_email_id={office_details.Form_Email_ID} to_user_id={office_details.Form_User_ID} openSearch={openSearch} openHam={openHam} search={search} open={open} type={office_details.Type} sub_type={office_details.Sub_Type} subject={"Office Contact"} formLabel={`Contact - ${office_details.Name}`} />
          </div>
        }
      </Layout>
  )
}

export default OfficeDetailsTemplate