import * as React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import GoogleSingleMap from "../../maps/google/contact_us_map"
import LocRatingMap from "../../map/loc-rating-map-details"
import "./ContactMap.scss"
// styles

// markup
const ContactMap = (props) => {

    // console.log("Office", props)

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = props.office_details.Latitude      
    mapItem['lng']  = props.office_details.Longitude
    mapItem['id']  = props.office_details.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );
    // Get Map Details

    return (
        <React.Fragment>
            <div className="contact-map-wrap">
                <ScrollAnimation animateIn="fadeIn">
                    {/* <GoogleSingleMap lat={props.office_details.Latitude ? parseFloat(props.office_details.Latitude) : ''} lng={props.office_details.Longitude ? parseFloat(props.office_details.Longitude) : ''} OfficeAddress={props.office_details.Location} OfficeName={props.office_details.Name} height={"500px"} zoom={12} infoWindow={props.infoWindow}/> */}

                    <LocRatingMap data={mapItems} amenities_tab={props.all_tabs} streetview_tab={props.all_tabs} transport_tab={props.all_tabs} sold_price_tab={props.all_tabs} />
                </ScrollAnimation>
            </div>
        </React.Fragment>
    )
}

export default ContactMap