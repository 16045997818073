import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Modal from "react-bootstrap/Modal"
import PlayVideo from '../../../components/Play/PlayVideo';
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';
import $ from "jquery"

import { youtube_parser, CustomLinks } from "../../common/utils";
import {ImageModule} from "../../../modules/Image_Module"
import OpeningHours from "../OpeningHours/OpeningHours"
import MemberForm from "../../forms/member-form"
import RequestAppraisal from "../../forms/request_appraisal"



import "../PersonDetails/PersonDetails.scss"
// Header component

const OffcDetails = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [isOpen,setIsOpen] = useState(false);
    const [formType,setFormType] = useState(false);

    var youtube_id = props.office_details && props.office_details.Embed_Video_URL && youtube_parser(props.office_details.Embed_Video_URL);
    const popupTypeForm = () => {
        setIsOpen(true);
        setFormType('')
    }
    var type = props.office_details && props?.office_details?.Type;
    var sub_type = props.office_details && props?.office_details?.Sub_Type;

    var page_url = typeof window !== "undefined" ? window.location.href : '';
    var to_user_id = props?.office_details?.Form_User_ID ? props?.office_details?.Form_User_ID : '';

    return (
        <React.Fragment>
            <section className="person-details office_details">
                <Container>
                    <Row>
                        <Col>
                        {
                            props.office_details && 
                            <div className="details-wrapper ">
                                <div className="surveyor-img img-zoom">
                                    <ScrollAnimation animateIn='fadeInRight'  animateOnce={true}>
                                        <picture>
                                            {props.office_details.Image && 
                                                <ImageModule ImageSrc={props.office_details.Image} ggfx_results={props.office_details.ggfx_results} imagename="offices.Image.detail_image" strapi_id={props.office_details.id} altText={props.office_details.Name+ " - Strettons"}/> 
                                            }
                                        </picture>                                        
                                    </ScrollAnimation>
                                    {
                                        props.office_details.Embed_Video_URL && 
                                        <div className="video-buttons" onClick={(e) => { setPlay(true) }}>
                                            <strong className="video-btn">
                                                <i className="icon-video-black"></i>
                                            </strong>
                                        </div>
                                    }                                    
                                </div>

                                <div className="details-info">
                                    <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
                                        <h1>{props.office_details.Name}</h1>
                                        <div className="getin-touch offc_details">
                                            <div className="content-wrapper">

                                                {
                                                    props.office_details.Phone_No && 
                                                    <div className="icons">
                                                        <a href={`tel:${props.office_details.Phone_No}`}><i className="icon-call"></i></a>
                                                        <a href={`tel:${props.office_details.Phone_No}`} className="tel">{props.office_details.Phone_No}</a>
                                                    </div>
                                                }
                                                
                                                {
                                                    props.office_details.Email_ID && 
                                                    <div className="icons" onClick={() => popupTypeForm('member_form')}>
                                                        <a href="javascript:;">
                                                            <i className="icon-msg"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mail">
                                                            {props.office_details.Email_ID}
                                                        </a>                                        
                                                    </div>
                                                }
                                                
                                                {
                                                    props.office_details.Location && 
                                                    <div className="icons">
                                                        <a><i className="icon-map"></i></a>
                                                        {props?.office_details?.google_profile_link?<a className="content-links" target="_blank" href={props?.office_details?.google_profile_link}>{props.office_details.Location}</a>:props.office_details.Location}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="btn-wrapper">
                                            <a href="javascript:;" onClick={(e)=>props.handleClick("contact_office")} className="btn btn-primary black_btn">
                                                contact office
                                            </a>
                                            <Link to={CustomLinks.appraisal_from} state={{to_email_id:props.office_details.Email_ID, to_user_id:props.office_details.Form_User_ID, page_url: props.location.href, type:type, sub_type:sub_type}} className="btn btn-primary">
                                                book an appraisal
                                            </Link>
                                        </div>                                                                                                               

                                        {props.office_details.Content && HTMLReactParser(props.office_details.Content)}

                                    </ScrollAnimation>

                                    { props.office_details.Opening_Hours && props.office_details.Opening_Hours.length > 0 && <OpeningHours Opening_Hours={props.office_details.Opening_Hours} /> }
                                </div>
                            </div>
                        }
                            
                        </Col>
                    </Row>
                </Container>
            </section>
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={youtube_id}
                isAutoPlay={1}
            />

            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
            >
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {props.office_details.Name}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MemberForm to_email_id={props.office_details.Email_ID} formName={`Individual Contact - ${props.office_details.Name}`} formLabel={`Person ${props.office_details.Name}`} page_url={page_url} type={type} sub_type={sub_type} to_user_id={to_user_id} />
                </Modal.Body>
{/*
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                    <Modal.Title className="w-100">
                        <h3 className="text-center content-primary-color mb-0">Request an Appraisal</h3>
                        <p>Complete the form and one of our local experts will be in touch to arrange your market appraisal.
                    </p>
                    </Modal.Title>                    
                </Modal.Header>
                <Modal.Body>
                    <RequestAppraisal page_url={page_url} type={type} sub_type={sub_type} to_user_id={to_user_id} popup_form={true} />
                </Modal.Body> */}
            </Modal>
        </React.Fragment>
    );
};
export default OffcDetails;
