import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from "react-bootstrap"

import { menuLinks } from "../../common/pageUtils"

import "../../PropertyDetails/Commercial/Commercial.scss"
// Header component

const WhatWeDo = (props) => {

    return (
        <React.Fragment>
            <section className="commercial-service what_we_do">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                {
                                    props.What_We_Do && 
                                    <div className="commercial">
                                        <h2>{props.What_We_Do.Title}</h2>
                                        <p>{props.What_We_Do.Content}</p>

                                        <div className="service-wrapper">
                                            {
                                                props.What_We_Do.Links && props.What_We_Do.Links.length > 0 && props.What_We_Do.Links.map((item, i) => {

                                                    var menu_link = "javascript:;";
                                                    if(item.Link){
                                                        menu_link = menuLinks(item.Link);
                                                    } 

                                                    return(
                                                        <>
                                                            {
                                                                item.Link ? 
                                                                <Link to={menu_link} className="services">
                                                                    <h3>{item.Label}</h3>
                                                                    <i className="right-slide"></i>
                                                                </Link>
                                                                :
                                                                <a href={menu_link} className="services">
                                                                    <h3>{item.Label}</h3>
                                                                    <i className="right-slide"></i>
                                                                </a>
                                                            }
                                                            
                                                        </>
                                                    )
                                                })
                                            }                                            
                                        </div>

                                        {
                                            props.What_We_Do.Explore_Text && 
                                            <div className="service-info">
                                                {HTMLReactParser(props.What_We_Do.Explore_Text)}
                                            </div>
                                        }
                                    </div>
                                }
                                
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default WhatWeDo;
