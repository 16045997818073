import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import "./OpeningHours.scss"
// Header component

const Opening = (props) => {
    return (
        <React.Fragment>
            <section className="opening-hours">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                <div className="time-wrapper">

                                    <h2>Opening Hours</h2>

                                    {
                                        props.Opening_Hours && props.Opening_Hours && props.Opening_Hours.length > 0 && props.Opening_Hours.map((item, index) => {
                                            return(
                                                <div className="timing">
                                                    <span className="day">{item.Week_Day}</span><span className="opening-time">{item.Timing}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default Opening;
