import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button, Breadcrumb } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import { navigate } from "@reach/router";

import {CustomLinks} from "../common/utils";

import "./PersonLinks.scss"
// Header component

const OfficeLinks = (props) => {
  return (
    <React.Fragment>
      <section className="Person-nav person-nav-back">
        <Container>
          <Row>
            <Col>
              <div className="sm-menu-list d-lg-none">
                <Link to={CustomLinks.get_in_touch}><span className="sm-text">  &lt;  back to contact us </span></Link>
              </div>
              <div className="breadcrumb d-none d-lg-flex">
                <Breadcrumb>
                  <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                  <li className="breadcrumb-item"><Link to={`${CustomLinks.get_in_touch}`}>Get in Touch</Link></li>
                  <li className="breadcrumb-item active"><Link to='/'>{props.page_title}</Link></li>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default OfficeLinks;
